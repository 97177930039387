//*// BEHAVIORS //*//

.newsImageContainer {
	float:right;
	margin: 0 0 @plone-padding-base-vertical @plone-padding-base-vertical;
	border-radius: @plone-border-radius-base;
	box-shadow: 0 1px 2px rgba(0,0,0,.17);
	overflow: hidden;

    a {
        display: table;
        background-color: @plone-body-bg;
        }
    a img { display: block; }
	figcaption {
        display: table-caption;
        caption-side: bottom;
		font-size: @plone-font-size-small;
		background: @plone-portlet-footer-bg;
	}
}
