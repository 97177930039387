//*// THUMBS //*//

//*//photoAlbum
.photoalbumEntryWrapper {
	display: block;
}

.photoAlbumEntry {
	display: inline-block;
	margin: 5px;
	vertical-align: middle;

	a {
		text-align: center;
		vertical-align: middle;
		display:
			table-cell;
		font-weight: @plone-font-weight-regular;
		box-shadow: 0 1px 3px rgba(0, 0, 0, .05);
		border: @plone-portlet-border;
		border-radius: @plone-border-radius-base;
		background: @plone-portlet-footer-bg;
		width: 220px;
		height: 240px;

		.photoAlbumEntryTitle {
			hyphens: auto;
			word-break: break-word;
		}
	}

	img {
		max-width: 200px;
		max-height: 200px;
	}
}