//*// SCAFFOLDING //*//

//*// Reset the box-sizing
* {
  .box-sizing(border-box);
}
*::before,
*::after {
  .box-sizing(border-box);
}


//*// Body reset
html {
  font-size: 62.5%;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

body {
  font-family: @plone-font-family-base;
  font-size: @plone-font-size-base;
  font-weight: @plone-font-weight-medium;
  line-height: @plone-line-height-base;
  color: @plone-text-color;
  background-color: @plone-body-bg;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

//*// Reset fonts for relevant elements
input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}


//*// Links

a {
  color: @plone-link-color;
  text-decoration: none;

  &:hover,
  &:focus {
    color: @plone-link-hover-color;
    text-decoration: underline;
  }

  &:focus {
    .tab-focus(); //--> NOT DONE
  }
}


//*// Figures
figure {
  margin: 0;
}


//*// Images

img {
  vertical-align: middle;
}


// Horizontal rules

hr {
  margin-top:    @plone-line-height-computed;
  margin-bottom: @plone-line-height-computed;
  border: 0;
  border-top: 1px solid @plone-hr-border;
}


// Only display content to screen readers
//
// See: http://a11yproject.com/posts/how-to-hide-content/

.sr-only, .hiddenStructure,
.crud-form .header-select{
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
  color: #fff;
  background-color: #000;
}
.sr-only a, .hiddenStructure a {
  color: #fff;
  background-color: #000;
}

// Use in conjunction with .sr-only to only display content when it's focused.
// Useful for "Skip to main content" links; see http://www.w3.org/TR/2013/NOTE-WCAG20-TECHS-20130905/G1
// Credit: HTML5 Boilerplate

.sr-only-focusable {
  &:active,
  &:focus {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
  }
}