//*// CONTENTTYPE ICONS //*//

[class*="contenttype-"]::before,
.contenttype-file #crud-edit-File-widgets-view_title::before {
    font-family:"Fontello";
    font-size: 100%;
    padding: 0;
    margin:0;
    position: relative;
    left: inherit;
    display: inline-block;
    color: inherit;
    width: 20px;
    height: 20px;
    text-align: center;
    margin-right: @plone-padding-base-vertical;
    content: '\e834';
}

.contenttype-folder::before     { content: '\e801'; }
.contenttype-document::before   { content: '\e80e'; }
.contenttype-file::before       { content: '\e811'; }
.contenttype-link::before       { content: '\e806'; }
.contenttype-image::before      { content: '\e810'; }
.contenttype-collection::before { content: '\e808'; }
.contenttype-event::before      { content: '\e809'; }
.contenttype-news-item::before  { content: '\e80f'; }

.outer-wrapper {
    .contenttype-file::before   { content: none;    }

    /* On folder_factories view, from toolbar: Add new... -> More... */
    #quickAdd .contenttype-file::before { content: '\e811'; }
}

.contenttype-file #crud-edit-File-widgets-view_title::before {
    content: '\e811';
}

.mime-icon { margin-right:8px; }
